import './src/css/global.scss'

export { default as wrapRootElement } from './src/classes/ReduxWrapper';

export function onClientEntry() {

    if (process.env.NODE_ENV !== 'development') {
        console.log = () => {}
        console.info = () => {}
        console.error = () => {}
    }

}
