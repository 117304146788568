export const PLAY_WITH_PLAYER = 'PLAY_WITH_PLAYER'
export const PAUSE_WITH_PLAYER = 'PAUSE_WITH_PLAYER'
export const PLAY_WITH_BUTTON = 'PLAY_WITH_BUTTON'
export const PAUSE_WITH_BUTTON = 'PAUSE_WITH_BUTTON'
export const STOP_WITH_PLAYER = 'STOP_WITH_PLAYER'
export const PREVIOUS_WITH_PLAYER = 'PREVIOUS_WITH_PLAYER'
export const NEXT_WITH_PLAYER = 'NEXT_WITH_PLAYER'
export const UPDATE_TRACK_DATA = 'UPDATE_TRACK_DATA'
export const UPDATE_SINGLE_TRACK_DATA = 'UPDATE_SINGLE_TRACK_DATA'
export const NO_ACTION = 'NO_ACTION'
export const DESTROY_SESSION = 'DESTROY_SESSION'

export const playWithPlayer = (index, track) => {
    console.log("playWithPlayer")
    return { type: PLAY_WITH_PLAYER, payload: { index, track } }
}

export const pauseWithPlayer = (index, track) => {
    console.log("pauseWithPlayer")
    return { type: PAUSE_WITH_PLAYER, payload: {index, track} }
}

export const playWithButton = (index, track) => {
    console.log("playWithButton")
    return { type: PLAY_WITH_BUTTON, payload: { index, track } }
}

export const pauseWithButton = (index, track) => {
    console.log("pauseWithButton")
    return { type: PAUSE_WITH_BUTTON, payload: { index, track } }
}

export const stopWithPlayer = (index, track) =>  {
    console.log("stopWithPlayer")
    return { type: STOP_WITH_PLAYER, payload: {index, track} }
}

export const previousWithPlayer = () =>  {
    console.log("previousWithPlayer")
    return { type: PREVIOUS_WITH_PLAYER, payload: { } }
}

export const nextWithPlayer = () =>  {
    console.log("nextWithPlayer")
    return { type: NEXT_WITH_PLAYER, payload: { } }
}

export const updateTrackData = data =>  {
    console.log("updateTrackData", data)
    return { type: UPDATE_TRACK_DATA, payload: data }
}

export const updateSingleTrackData = (index, showLR) =>  {
    console.log("updateTrackData")
    return { type: UPDATE_SINGLE_TRACK_DATA, payload: { index, showLR } }

}

export const destroySession = () =>  {
    return { type: DESTROY_SESSION, payload: { } }
}