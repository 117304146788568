// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-post-js": () => import("/opt/build/repo/src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-post-list-js": () => import("/opt/build/repo/src/templates/post-list.js" /* webpackChunkName: "component---src-templates-post-list-js" */),
  "component---src-templates-faq-list-js": () => import("/opt/build/repo/src/templates/faq-list.js" /* webpackChunkName: "component---src-templates-faq-list-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-information-infoforusersurvey-js": () => import("/opt/build/repo/src/pages/information/infoforusersurvey.js" /* webpackChunkName: "component---src-pages-information-infoforusersurvey-js" */),
  "component---src-pages-mission-index-js": () => import("/opt/build/repo/src/pages/mission/index.js" /* webpackChunkName: "component---src-pages-mission-index-js" */),
  "component---src-pages-privacy-index-js": () => import("/opt/build/repo/src/pages/privacy/index.js" /* webpackChunkName: "component---src-pages-privacy-index-js" */),
  "component---src-pages-sotd-index-js": () => import("/opt/build/repo/src/pages/sotd/index.js" /* webpackChunkName: "component---src-pages-sotd-index-js" */),
  "component---src-pages-support-index-js": () => import("/opt/build/repo/src/pages/support/index.js" /* webpackChunkName: "component---src-pages-support-index-js" */),
  "component---src-pages-terms-index-js": () => import("/opt/build/repo/src/pages/terms/index.js" /* webpackChunkName: "component---src-pages-terms-index-js" */),
  "component---src-pages-thankyou-index-js": () => import("/opt/build/repo/src/pages/Thankyou/index.js" /* webpackChunkName: "component---src-pages-thankyou-index-js" */)
}

