import {
    PLAY_WITH_PLAYER, 
    PAUSE_WITH_PLAYER, 
    PLAY_WITH_BUTTON, 
    PAUSE_WITH_BUTTON, 
    STOP_WITH_PLAYER,
    PREVIOUS_WITH_PLAYER, 
    NEXT_WITH_PLAYER, 
    UPDATE_TRACK_DATA, 
    UPDATE_SINGLE_TRACK_DATA, 
    NO_ACTION, 
    DESTROY_SESSION
} from '../actions'

import PlayStateType from '../classes/PlayStateType'

const initialState = {
    currentPlayingTrackIndex: -1,
    playState: PlayStateType.NONE, 
    track: null, 
    tracks: [],
    trackCount: 0,
    latestDate: "0", 
    tillDate: "0", 
    loadedPageIndex: 0, 
    isFullLoaded: false, 
    actionType: NO_ACTION
}

export default (state = initialState, action) => {
    let track = null
    let idx = -1
    switch (action.type) {
        case PLAY_WITH_PLAYER:
        case PLAY_WITH_BUTTON:
            if (state.trackCount === 0) return state;
            return { ...state, 
                    playState: PlayStateType.PLAY, 
                    currentPlayingTrackIndex: action.payload.index, 
                    track: action.payload.track, 
                    actionType: action.type
                }
        case PAUSE_WITH_PLAYER:
        case PAUSE_WITH_BUTTON:
            if (state.trackCount === 0) return state;
            return { ...state, 
                    playState: PlayStateType.PAUSE, 
                    currentPlayingTrackIndex: action.payload.index, 
                    track: action.payload.track, 
                    actionType: action.type
                }
        case STOP_WITH_PLAYER:
            if (state.trackCount === 0) return state;
            return { ...state, 
                    playState: PlayStateType.STOP, 
                    currentPlayingTrackIndex: action.payload.index, 
                    track: action.payload.track, 
                    actionType: action.type
                }
        case PREVIOUS_WITH_PLAYER:
            if (state.trackCount === 0 || state.currentPlayingTrackIndex === 0) return state;
            idx = state.currentPlayingTrackIndex - 1 
            track = state.tracks[idx]
            return { ...state, 
                    currentPlayingTrackIndex: idx, 
                    track, 
                    actionType: action.type }
        case NEXT_WITH_PLAYER:
            if (state.trackCount === 0 || state.currentPlayingTrackIndex === state.trackCount - 1) return state;
            idx = state.currentPlayingTrackIndex + 1 
            track = state.tracks[idx]
            return { ...state, 
                    currentPlayingTrackIndex: idx, 
                    track, 
                    actionType: action.type }
        case UPDATE_TRACK_DATA:
            return { ...state, 
                    tracks: action.payload.tracks, 
                    trackCount: action.payload.tracks.length, 
                    latestDate: action.payload.latestDate,  
                    tillDate: action.payload.tillDate,  
                    loadedPageIndex: action.payload.loadedPageIndex, 
                    isFullLoaded: action.payload.isFullLoaded, 
                    actionType: action.type }
        case UPDATE_SINGLE_TRACK_DATA:
            const { index, showLR } = action.payload
            return { ...state, 
                    tracks: state.tracks.map(
                        (track, i) => i === index ? { ...track, showLR } : track
                    ),
                    actionType: action.type }
        case DESTROY_SESSION:
            return { ...initialState }
        default:
            return state
    }
}
