
class PlayStateType {
    static get NONE () {
        return "none";
    }
    static get PLAY () {
        return "play";
    }
    static get PAUSE () {
        return "pause";
    }
    static get STOP () {
        return "stop";
    }
}

export default PlayStateType
